import React, { useEffect, useState } from "react";
import "./App.css";
import "./components/menu-team/menuTeam.css";
import { getPadTime } from "./helpers/getPadTimer";
import useSound from "use-sound";
import clickBtn from "./sounds/clickButton.mp3";
import endSound from "./sounds/endSound.mp3";
import volchokSound from "./sounds/volchok.mp3";
import tenSecond from "./sounds/10s.mp3";
// import fortySixSecond from "./sounds/46s.mp3";
import plusFifteenSecondsSound from "./sounds/+15s.mp3";

function App() {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [isCountingTeam, setIsCountingTeam] = useState(false);
  const [startMaster, setStartMaster] = useState(false);
  const [startTeam, setStartTeam] = useState(false);
  const [playSoundClickBtn] = useSound(clickBtn);
  const [playSoundEndSound] = useSound(endSound);
  const [playSoundTenSec, { stop }] = useSound(tenSecond);
  // const [playSoundFortySix] = useSound(fortySixSecond);
  const [playPlusFifteenSecondsSound] = useSound(plusFifteenSecondsSound);
  const [playSoundVolchokSound] = useSound(volchokSound);

  const minutes = getPadTime(Math.floor(timeLeft / 60));
  const seconds = getPadTime(timeLeft - minutes * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      isCounting &&
        setTimeLeft((timeLeft) => (timeLeft > -1 ? timeLeft - 1 : 0));
    }, 1000);
    if (timeLeft === 0 && isCounting === true) {
      setIsCounting((isCounting) => (isCounting = false));
      setIsCountingTeam((isCountingTeam) => (isCountingTeam = true));
      setTimeLeft((timeLeft) => (timeLeft = 60));
    }
    return () => {
      clearInterval(interval);
    };
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const interval = setInterval(() => {
      isCountingTeam &&
        setTimeLeft((timeLeft) => (timeLeft > -1 ? timeLeft - 1 : 0));
    }, 1000);

    if (timeLeft === 0 && isCountingTeam === true) {
      setIsCountingTeam((isCountingTeam) => (isCountingTeam = false));
      setIsCounting((isCounting) => (isCounting = false));
      setTimeLeft((timeLeft) => (timeLeft = 0));
      playSoundEndSound();
    }
    // if(timeLeft===56){
    //   playSoundFortySix()
    // }
    if (timeLeft === 10) {
      playSoundTenSec();
    }
    return () => {
      clearInterval(interval);
    };
  }, [isCountingTeam, timeLeft]);

  const masterTimeStartGame = () => {
    startGame();
    setIsCounting(true);
    setIsCountingTeam(false);
    playSoundClickBtn();
  };
  const masterStandartTime = () => {
    standartTime();
    setIsCounting(true);
    setIsCountingTeam(false);
    playSoundClickBtn();
  };
  const teamTime = () => {
    playSoundClickBtn();
    if (timeLeft > 58 || timeLeft === 0) {
      playSoundVolchokSound();
    } else if (isCounting === true && timeLeft < 58) {
      playSoundVolchokSound();
    }
    standartTime();
    setIsCountingTeam(true);
    setIsCounting(false);
  };
  const startGame = () => {
    setTimeLeft((timeLeft) => (timeLeft = 120));
  };
  const standartTime = () => {
    setTimeLeft((timeLeft) => (timeLeft = 60));
  };
  const plusFifteenSeconds = () => {
    if (isCountingTeam) {
      stop()
      playSoundClickBtn();
      setTimeLeft((timeLeft) => timeLeft + 15);
      playPlusFifteenSecondsSound();
      // playSoundFortySix()
    }
  };
  const toggleStopAndStart = () => {
    if (!isCountingTeam && isCounting) {
      // stop timer master
      setIsCounting(false);
      setStartMaster(true);
      stop();
    } else if (!isCounting && isCountingTeam) {
      // stop timer team
      setIsCountingTeam(false);
      setStartTeam(true);
      stop();
    } else if (!isCountingTeam && !isCounting && startMaster) {
      // start timer master
      setIsCounting(true);
      setStartMaster(false);
    } else if (!isCountingTeam && !isCounting && startTeam) {
      // start timer team
      setIsCountingTeam(true);
      setStartTeam(false);
    }
  };
  return (
    <>
      <a href="https://danilaweb.ru/">
        <div className="logo"></div>
      </a>
      <div className="container">
        <div className="container__menu-team">
          <button className="start-game__btn" onClick={masterTimeStartGame}>
            start game
          </button>
          <div
            className={`container__menu-team__output ${
              isCounting ? "is-active__btn" : ""
            } ${
              isCountingTeam && timeLeft > 10
                ? "time-team"
                : isCountingTeam && "little-time"
            }`}
          >
            <span>{minutes}</span>
            <span>:</span>
            <span>{seconds}</span>
          </div>
          <div className="container__menu-team__btn">
            <ul>
              <li>
                <button
                  className={`${isCounting && "is-active__btn"}`}
                  onClick={masterStandartTime}
                >
                  time master
                </button>
              </li>
              <li>
                <button
                  className={`${
                    isCountingTeam && timeLeft > 10
                      ? "time-team"
                      : isCountingTeam && "little-time"
                  }`}
                  onClick={teamTime}
                >
                  time team
                </button>
              </li>
              <li>
                <button onClick={plusFifteenSeconds}>team+15</button>
              </li>
              <li>
                <button onClick={toggleStopAndStart}>stop/start</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
